import { useInView } from '@conte-ltd/components-animation'
import React from 'react'
import { ReactNode } from 'react'
import type {
  IntersectionOptions,
  InViewHookResponse,
} from 'react-intersection-observer'

export interface InViewProps extends IntersectionOptions {
  children: (props: InViewHookResponse) => ReactNode
}

export function InView({ children, ...otherProps }: InViewProps) {
  const inViewHook = useInView({
    ...otherProps,
  })

  return <>{children(inViewHook)}</>
}

import { useStyles, useBreakpoints } from '@conte-ltd/components-theme'
import { StyleProps, useMediaQuery } from '@conte-ltd/components-utils'
import { css } from '@emotion/react'
import React, { ImgHTMLAttributes, ReactNode } from 'react'
import { Theme } from '../styles/theme'
import { InViewFadeUp } from './in-view-fade'
import { Container } from './container'

export interface ServiceCardProps extends StyleProps {
  heading: ReactNode
  image: ImgHTMLAttributes<HTMLImageElement>
  content: ReactNode
}

export function ServiceCard({
  image,
  heading,
  content,
  ...other
}: ServiceCardProps) {
  const {
    display,
    justifyItems,
    alignSelf,
    gap,
    grid,
    width,
    height,
    padding,
    textAlign,
    bgColor,
  } = useStyles<Theme>()
  const bp = useBreakpoints()
  const isMobile = useMediaQuery(bp.lg)

  return (
    <InViewFadeUp>
      <div
        css={[
          display.grid,
          justifyItems.center,
          gap.all[10],
          padding.all['3xs'],
          bgColor.white,
          textAlign.center,
          isMobile
            ? [
                height[120],
                css`
                  grid-template-columns: 1fr auto;
                `,
              ]
            : [width[190], height[180]],
        ]}
        {...other}
      >
        <h3
          css={[
            alignSelf.end,
            css`
              font-size: 1.3rem;
              line-height: 1.15;

              @media ${bp.lg} {
                font-size: 1.2rem;
              }
            `,
          ]}
        >
          {heading}
        </h3>

        <div css={isMobile && [grid.row.span[2], alignSelf.center]}>
          <img width={isMobile ? 136 : 120} {...image} />
        </div>

        <p
          css={css`
            font-size: 1.2rem;

            @media ${bp.lg} {
              font-size: 1.1rem;
            }
          `}
        >
          {content}
        </p>
      </div>
    </InViewFadeUp>
  )
}

export interface ServiceCard2Props extends StyleProps {
  heading: ReactNode
  image: ImgHTMLAttributes<HTMLImageElement>
  content1: ReactNode
  content2: ReactNode
}

export function ServiceCard2({
  image,
  heading,
  content1,
  content2,
  ...other
}: ServiceCard2Props) {
  const {
    display,
    gap,
    alignContent,
    justifySelf,
    alignSelf,
    grid,
    maxWidth,
    padding,
    textAlign,
    lineHeight,
    bgColor,
    order,
  } = useStyles<Theme>()
  const bp = useBreakpoints()
  const isMobile = useMediaQuery(bp.lg)
  const isDesktop = !isMobile

  return (
    <div css={bgColor.lightgray}>
      <Container
        css={[
          display.grid,
          maxWidth[710],
          isMobile
            ? [
                alignContent.spaceBetween,
                gap.row.xs,
                padding.all.xs,
                padding.top.sm,
              ]
            : [
                gap.all.sm,
                padding.y.md,
                css`
                  grid-template-columns: 1fr auto;
                `,
              ],
        ]}
        {...other}
      >
        <h3
          css={[
            grid.column.span[2],
            isMobile && textAlign.center,
            css`
              font-size: 2.8rem;
              line-height: 1.15;

              @media ${bp.lg} {
                font-size: 1.4rem;
              }
            `,
          ]}
        >
          <InViewFadeUp>{heading}</InViewFadeUp>
        </h3>
        <div
          css={[justifySelf.center, alignSelf.center, isDesktop && order[2]]}
        >
          <InViewFadeUp>
            <img width={isMobile ? 120 : 260} {...image} />
          </InViewFadeUp>
        </div>
        <p
          css={[
            grid.column.span[2],
            isMobile && textAlign.center,
            isMobile && lineHeight.normal,
            css`
              font-size: 1.4rem;

              span {
                display: inline-block;
                vertical-align: 0;
              }

              @media ${bp.lg} {
                font-size: 1.3rem;
              }
            `,
          ]}
        >
          <InViewFadeUp>{content1}</InViewFadeUp>
        </p>
        <div
          css={[
            bgColor.white,
            padding.all.xs,
            css`
              font-size: 1.4rem;

              @media ${bp.lg} {
                font-size: 1.1rem;
              }
            `,
          ]}
        >
          <p>
            <InViewFadeUp>{content2}</InViewFadeUp>
          </p>
        </div>
      </Container>
    </div>
  )
}
